import { atom } from 'recoil'

// store for onepager things

// display list item detail modal
export const showListDetailModal = atom({
  key: 'show_list_detail_modal',
  default: false,
})

// hold the ID of the content to display / edit in modal
export const detailModalContentID = atom({
  key: 'detail_modal_content_id',
  default: null,
})

// hold the ID of the content_block to display / edit in modal
export const detailModalContentBlockID = atom({
  key: 'detail_modal_content_block_id',
  default: null,
})

// section data for modal
export const detailModalSectionData = atom({
  key: 'detail_modal_section_data',
  default: null,
})

// toggle explainer / read more modal
export const toggleExplainerModal = atom({
  key: 'toggle_explainer_modal',
  default: false,
})

// PUBLIC - logged in check
export const publicIsLoggedIn = atom({
  key: 'publicIsLoggedIn',
  default: false,
})

// PUBLIC - current template ID
export const currentTemplateID = atom({
  key: 'currentTemplateID',
  default: null,
})

// PUBLIC - auto saving
export const autoSavingStatus = atom({
  key: 'autoSavingStatus',
  default: false,
})

// PUBLIC - nagivateSwiper
export const nagivateSwiper = atom({
  key: 'nagivateSwiper',
  default: 0,
})

// PUBLIC - Blocks Mode Type
export const blockPageMode = atom({
  key: 'blockPageMode',
  default: 'card',
})

// PUBLIC - dockedNav
export const dockedNav = atom({
  key: 'dockedNav',
  default: true,
})

import { atom, selector } from 'recoil'

// import patterns
import { waves_pattern_svg } from '@/components/onepager/patterns/waves'

export const design = atom({
  key: 'designSettings',
  default: {
    layout: 'default',
    header_layout: 'default',
    canvas_width: 7,
    canvas_style: 'default',
    font: 'cera',
    section_title_font: 'default',
    section_text_color: 'black',
    section_bg_color: 'rgb(255, 255, 255)',
    text_color: 'black',
    headline_text_size: 72,
    headline_lineheight: 72,
    headline_padding: 0,
    sub_headline_text_size: 20,
    sub_headline_lineheight: 36,
    sub_headline_padding: 0,
    sub_headline_margintop: 0,
    author_text_size: 16,
    author_margin_bottom: 20,
    background_fullsize: 'default',
    background: {
      bg_color: 'rgba(255, 255, 255, 1)',
      pattern: 'waves',
      pattern_color: 'rgba(0, 0, 0, 0.1)',
      pattern_opacity: [100],
      position: 'center',
      image: '',
      image_opacity: [100],
      type: 'svg',
      footer: 'tiltleft',
    },
    theme_settings: {
      bg_color: '#FFF',
      canvas_border: '#374151',
      canvas_inner_shadow: '#E2E8F0',
      canvas_thickness: '2px',
    },
  },
})

export const cssVars = selector({
  key: 'cssVarsBuilder',
  get: ({ get }) => {
    let leftRightShadow = get(design).theme_settings?.canvas_thickness
    leftRightShadow = leftRightShadow?.replace('px')
    leftRightShadow = parseInt(leftRightShadow)
    leftRightShadow = leftRightShadow + 3

    let cssVars = {
      'overall-bg-color': get(design).theme_settings?.bg_color,
      'header-text-color': get(design).text_color,
      'header-title-font':
        get(design).font == 'cera'
          ? "'CeraPro-Black', 'Helvetica Neue', Helvetica, Arial, sans-serif"
          : "'Pensum-Black', serif",
      'header-bg-color': get(design).background?.bg_color,
      'canvas-border': get(design).theme_settings?.canvas_border,
      'canvas-border-50': '#000',
      'canvas-inner-shadow': get(design).theme_settings?.canvas_inner_shadow,
      'canvas-thickness': get(design).theme_settings?.canvas_thickness,
      'canvas-block-shadow': `inset -${
        get(design).theme_settings?.canvas_thickness
      } 0 0 ${get(design).theme_settings?.canvas_border}, inset 0 -${
        get(design).theme_settings?.canvas_thickness
      } 0 ${get(design).theme_settings?.canvas_border}, inset 0 0 0 3px ${
        get(design).theme_settings?.canvas_inner_shadow
      }, inset -${leftRightShadow}px 0 0 ${
        get(design).theme_settings?.canvas_inner_shadow
      }, inset 0 -${leftRightShadow}px 0 ${
        get(design).theme_settings?.canvas_inner_shadow
      }`,
      'canvas-block-shadow-no-right': ` inset 0 -${
        get(design).theme_settings?.canvas_thickness
      } 0 ${get(design).theme_settings?.canvas_border}, inset 0 0 0 3px ${
        get(design).theme_settings?.canvas_inner_shadow
      }, inset -3px 0 0 ${
        get(design).theme_settings?.canvas_inner_shadow
      }, inset 0 -${leftRightShadow}px 0 ${
        get(design).theme_settings?.canvas_inner_shadow
      }`,
      'canvas-block-shadow-no-bottom': `inset -${
        get(design).theme_settings?.canvas_thickness
      } 0 0 ${get(design).theme_settings?.canvas_border},  inset 0 0 0 3px ${
        get(design).theme_settings?.canvas_inner_shadow
      }, inset -3px 0 0 ${
        get(design).theme_settings?.canvas_inner_shadow
      }, inset 0 -3px 0 ${get(design).theme_settings?.canvas_inner_shadow}`,
      'canvas-block-shadow-no-bottom-right': `  inset 0 0 0 3px ${
        get(design).theme_settings?.canvas_inner_shadow
      }, inset -3px 0 0 ${
        get(design).theme_settings?.canvas_inner_shadow
      }, inset 0 -3px 0 ${get(design).theme_settings?.canvas_inner_shadow}`,
      'canvas-block-shadow-custom-bg': `inset -${
        get(design).theme_settings?.canvas_thickness
      } 0 0 ${get(design).theme_settings?.canvas_border}, inset 0 -${
        get(design).theme_settings?.canvas_thickness
      } 0 ${
        get(design).theme_settings?.canvas_border
      }, inset 0 0 0 3px rgba(0, 0, 0, 0.05), inset -${leftRightShadow}px 0 0 rgba(0, 0, 0, 0.05), inset 0 -${leftRightShadow}px 0 rgba(0, 0, 0, 0.05)`,
      'canvas-block-section-shadow': `inset -1px 0 0 ${
        get(design).theme_settings?.canvas_border
      }, inset 0 -1px 0 ${
        get(design).theme_settings?.canvas_border
      }, inset 0 0 0 3px ${get(design).theme_settings?.canvas_inner_shadow}`,
      'canvas-block-section-shadow-custom-bg': `inset -1px 0 0 ${
        get(design).theme_settings?.canvas_border
      }, inset 0 -1px 0 ${get(design).theme_settings?.canvas_border}`,
      'canvas-block-table-shadow': `inset -${
        get(design).theme_settings?.canvas_thickness
      } 0 0 ${get(design).theme_settings?.canvas_border}, inset 0 -${
        get(design).theme_settings?.canvas_thickness
      } 0 ${
        get(design).theme_settings?.canvas_border
      }, inset 0 -${leftRightShadow}px 0 ${
        get(design).theme_settings?.canvas_inner_shadow
      }`,
      'canvas-block-table-shadow-custom-bg': `inset -${
        get(design).theme_settings?.canvas_thickness
      } 0 0 ${get(design).theme_settings?.canvas_border}, inset 0 -${
        get(design).theme_settings?.canvas_thickness
      } 0 ${
        get(design).theme_settings?.canvas_border
      }, inset 0 -${leftRightShadow}px 0 rgba(0, 0, 0, 0.1)`,
      'canvas-block-table-shadow-custom-bg-floating': ` inset 0 -${
        get(design).theme_settings?.canvas_thickness
      } 0 ${
        get(design).theme_settings?.canvas_border
      }, inset 0 -${leftRightShadow}px 0 rgba(0, 0, 0, 0.1)`,
      'canvas-section-custom-bg-toplevel': get(design).section_bg_color
        ? get(design).section_bg_color
        : '#FFF',
      'canvas-table-cell-shadow': `inset -${
        get(design).theme_settings?.canvas_thickness
      } 0 0 ${get(design).theme_settings?.canvas_border}, inset 0 -${
        get(design).theme_settings?.canvas_thickness
      } 0 ${get(design).theme_settings?.canvas_border}`,
      'canvas-table-cell-shadow-custom-bg': `inset 0 -${
        get(design).theme_settings?.canvas_thickness
      } 0 ${get(design).theme_settings?.canvas_border}`,
    }

    cssVars['header-image'] = 'url(' + get(design).background?.image + ')'
    cssVars['header-bg-repeat'] = 'no-repeat'
    cssVars['header-bg-size'] = 'cover'
    cssVars['header-bg-position'] = get(design).background?.position
    cssVars['header-bg-opacity'] =
      get(design).background?.image_opacity[0] / 100

    return cssVars
  },
})

// css Vars
// export const cssVars = atom((get) => {

//   let cssVars = {
//     'overall-bg-color': get(design).theme_settings?.bg_color,
//     'header-text-color': get(design).text_color,
//     'header-title-font': (get(design).font == 'cera') ? "'CeraPro-Black', 'Helvetica Neue', Helvetica, Arial, sans-serif" : "'tiempos-black', serif",
//     'header-bg-color': get(design).background?.bg_color,
//     'header-pattern': get(design).background?.pattern,
//     'header-pattern-color': get(design).background?.pattern_color,
//     'canvas-border': get(design).theme_settings?.canvas_border,
//     'canvas-border-50': '#000',
//     'canvas-inner-shadow': get(design).theme_settings?.canvas_inner_shadow,
//     'canvas-thickness': get(design).theme_settings?.canvas_thickness,
//     'canvas-block-shadow': 'inset -2px 0 0 var(--canvas-border), inset 0 -2px 0 var(--canvas-border), inset - 1px 0 0 5px var(--canvas - inner - shadow);',
//   }

//   // check if header is SVG type
//   if (get(design).background?.type == 'svg') {

//     cssVars['header-image'] = waves_pattern_svg.replace('%%COLOR%%', get(design).background?.pattern_color)
//     cssVars['header-bg-repeat'] = 'repeat'
//     cssVars['header-bg-size'] = 'auto'
//     cssVars['header-bg-position'] = 'auto'

//   } else {

//     cssVars['header-image'] = 'url(' + get(design).background?.image + ')'
//     cssVars['header-bg-repeat'] = 'no-repeat'
//     cssVars['header-bg-size'] = 'cover'
//     cssVars['header-bg-position'] = get(design).background?.position
//     cssVars['header-bg-opacity'] = get(design).background?.image_opacity[0] / 100

//   }

//   return cssVars

// })

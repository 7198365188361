import { atom, selector, atomFamily } from 'recoil'

// Store for Template Layout 

export const layoutIds = atom({
  key: 'layout_ids',
  default: [],
});


export const layout = atomFamily({
  key: 'layout',
  default: {
    gridarea: {},
    sectionID: null
  },
});
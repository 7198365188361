import { atom } from 'recoil'

export const opTitle = atom({
  key: 'op_title',
  default: null,
})

export const opDesc = atom({
  key: 'op_desc',
  default: null,
})

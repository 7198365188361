import { atom, selector, atomFamily } from 'recoil'

export const sectionIds = atom({
  key: 'section_ids',
  default: [],
})

export const section = atomFamily({
  key: 'section',
  default: {},
})

import { atom, selector } from 'recoil'

import * as GridTemplateParser from 'grid-template-parser'
import { grid } from 'grid-template-parser/lib/grid'

//
//  GRID MATRIX
//
export const grid_matrix = atom({
  key: 'gridMatrix',
  default: '6x6',
})

export const used_op_ids = atom({
  key: 'usedOpIds',
  default: [],
})

export const grid_rows = selector({
  key: 'gridMatrixRow',
  get: ({ get }) => {
    let gridM = get(grid_matrix)
    gridM = gridM.split('x')
    return gridM[0]
  },
})

export const grid_columns = selector({
  key: 'gridMatrixColumn',
  get: ({ get }) => {
    let gridM = get(grid_matrix)
    gridM = gridM.split('x')
    return gridM[1]
  },
})

export const total_boxes = selector({
  key: 'gridTotalBoxes',
  get: ({ get }) => {
    let gridM = get(grid_matrix)
    gridM = gridM.split('x')
    return gridM[0] * gridM[1]
  },
})

export const grid_selected_area = atom({
  key: 'gridSelectedArea',
  default: {
    'grid-row-start': 0,
    'grid-column-start': 0,
    'grid-row-end': 0,
    'grid-column-end': 0,
  },
})

export const grid_area = selector({
  key: 'grid_area',
  get: ({ get }) => {
    let _GridTemplateParser = GridTemplateParser
    let template = _GridTemplateParser.template
    let area = _GridTemplateParser.area

    let gridLayout = {}
    let columnsArray = setColumnsArray(get(grid_rows), get(grid_columns))
    let globalRow = 0

    // set base grid
    for (var i = 0; i < get(total_boxes); i++) {
      // vars
      let setIndex = i + 1
      let column = i

      const columndEndCheck = columnsArray.filter(x => x == setIndex - 1)
      if (columndEndCheck[0]) {
        globalRow = globalRow + 1
      }

      if (globalRow > 0) {
        column = column - columnsArray[globalRow - 1]
      }

      gridLayout['opB' + setIndex] = area({
        x: column,
        y: globalRow,
        width: 1,
        height: 1,
      })
    }

    const areasCompute = template({
      width: get(grid_columns),
      height: get(grid_rows),
      areas: gridLayout,
    })

    return areasCompute
  },
})

// EOA

export const author = atom({
  key: 'author',
  default: {
    name: 'Your name',
    tag: 'Awesome Person',
    photo: '',
    link: '',
    hide: 'hide',
  },
})

export const share_settings = atom({
  key: 'share_settings',
  default: {
    image_url: '',
  },
})

export const explainer = atom({
  key: 'explainer',
  default: {
    button_title: '',
    button_subtitle: '',
    content_title: 'How it works',
    content_subtitle: 'Learn...',
    content_cover: '',
    content_html: '',
  },
})

export const slug = atom({
  key: 'slug',
  default: null,
})

export const settings = atom({
  key: 'templateSettings',
  default: {
    author: {
      name: 'Your name',
      tag: 'Awesome Person',
      photo: '',
      link: '',
      hide: 'hide',
    },
    media: {
      url: '',
      thumbnail: '',
      type: '',
      title: 'Watch Video',
      subtitle: '',
    },
    branding: {
      logo_text: '#onepager',
      logo_image: '',
      link: '',
    },
    cta: {
      text: '',
      link: '',
    },
  },
})

function setColumnsArray(row, column) {
  let array = []
  for (var i = 0; i < row; i++) {
    if (i > 0) {
      let num = i * parseInt(column)
      array.push(num)
    }
  }
  return array
}

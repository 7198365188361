import { atom, selector, atomFamily } from 'recoil'

// Store for Template Content

export const content_loaded = atom({
  key: 'content_loaded',
  default: false,
})

// all content boxes ( each will be associated with a section)
export const contentIds = atom({
  key: 'content_ids',
  default: [],
})

// each content
export const content = atomFamily({
  key: 'content',
  default: {},
  // effects_UNSTABLE: contentID => [syncStorageEffect(contentID)],
})

// some content will have blocks (cards, tasks, etc)
export const content_block = atomFamily({
  key: 'content_block',
  default: {},
  // effects_UNSTABLE: contentID => [syncStorageEffect(contentID)],
})

export const contentBlockIds = atom({
  key: 'content_block_ids',
  default: [],
  // effects_UNSTABLE: contentID => [syncStorageEffect(contentID)],
})

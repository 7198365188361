import { atom, selector, atomFamily } from 'recoil'

export const isBuilder = atom({
  key: 'isbuilder',
  default: false,
})

export const screen = atom({
  key: 'screen',
  default: null,
})

export const screenOpen = atom({
  key: 'screen_open',
  default: false,
})

export const openAddBlock = atom({
  key: 'open_ad_block',
  default: false,
})

export const addBlockIndex = atom({
  key: 'add_block_index',
  default: false,
})

export const mediaSelectorOpen = atom({
  key: 'media_open',
  default: false,
})

export const editSectionId = atom({
  key: 'edit_section_id',
  default: null,
})

export const editSectionIndex = atom({
  key: 'edit_section_index',
  default: null,
})

export const addedBlockID = atom({
  key: 'added_block_id',
  default: null,
})

export const editSectionWrapperId = atom({
  key: 'edit_section_wrapper_id',
  default: null,
})

export const addSectionToWrapper = atom({
  key: 'add_section_to_wrapper',
  default: null,
})

export const addSectionToBlankLayoutId = atom({
  key: 'add_section_to_blank_layout_id',
  default: null,
})

export const canExitAfterSave = atom({
  key: 'canSafelyExit',
  default: true,
})
